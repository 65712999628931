import React from 'react'

const Button = ({text, isBorder, iconLeft, iconRight, onClick, width, height, background,type}) => {
  return (
    <div 
        className={`flex flex-row justify-center items-center px-4 py-2 rounded-full cursor-pointer ${background ? `bg-backgroundButton`: `bg-none` } ${isBorder ? 'border-gold border-solid border' : 'bg-background900'} ${width} ${height} `}
        onClick={() => onClick()}
        type={type}
    >
       {iconLeft && <img src={iconLeft} alt="icon" className="mr-2" />}
       {text && <span className='text-tiny text-gold' style={{color: '#E5D062'}}>{text}</span>}
       {iconRight && <img src={iconRight} alt="icon" className="ml-2" />}
       {/* {text && <span>{text}</span>} */}
    </div>
  )
}

export default Button