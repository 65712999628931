import { Progress } from "@material-tailwind/react";
import "animate.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  box,
  cart_bag,
  crown,
  gmt_gold_logo,
  money,
  order,
  shield,
  shield_green,
  shield_red,
  size,
  timer
} from "../assets/icons";
import Button from "../components/Button";
import SellerReviewCard from "../components/SellerReviewCard";
import SmallImage from "../components/SmallImage";
import WatchFeature from "../components/WatchFeature";
import { updateCart } from "../store/cartSlice";
import SellerCard from "./SellerCard";

function ProductDetail({
  brand,
  model,
  price,
  currency,
  specs,
  tags,
  seller_detail,
  about,
  video,
  rating,
  images,
}) {
  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let [over, setOver] = useState(false);

  const authenticate = useSelector((state) => state.user);

  const watchDetail = location?.state?.watchDetail;

  const userDetail = useSelector((state) => state.user.userDetail);

  const [open, setOpen] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(
    watchDetail?.images[0]?.src
  );
  const [isAtBottom, setIsAtBottom] = useState(false);
  const rightSectionRef = useRef(null);

  // Scroll handler
  const handleScroll = () => {
    const rightSection = rightSectionRef.current;
    if (rightSection) {
      const { scrollTop, scrollHeight, clientHeight } = rightSection;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    }
  };
  useEffect(() => {
    const rightSection = rightSectionRef.current;
    if (rightSection) {
      rightSection.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      if (rightSection) {
        rightSection.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const handleBackground = (newBackgoundImage) => {
    setBackgroundImage(newBackgoundImage);
  };

  const handleNegotiate = () => {
    setOpen(true);
  };
  return (
    <div className="bg-background800">
      <div
        // style={{ height: "calc(100vh - 4.5rem)" }}
        className={`flex flex-col h-full sm:h-[calc(100vh-4.5rem)] sm:flex-row w-auto bg-background800 no-scrollbar  overflow-y-scroll`}
      >
        <div
          className={`flex flex-col justify-start items-center  sm:basis-1/2 h-auto sm:h-fit`}
        >
          <div
            className={
              "flex flex-col justify-start items-start relative overflow-hidden sm:flex-row sm:w-full"
            }
          >
            <div className={`sm:order-2 sm:w-full `}>
              {/* {watchDetail?.images && */}
              <img
                src={backgroundImage}
                alt={watchDetail?.images[0]?.image_type}
                className="w-full h-[435px] sm:h-[calc(100vh-4.5rem)] object-cover"
              />
              {/* }  */}
            </div>
            <div
              className={`flex flex-row justify-start items-center gap-4 w-full no-scrollbar overflow-scroll pl-4 absolute bottom-3  sm:flex-col sm:order-1 sm:pl-0 sm:items-start  sm:w-fit sm:justify-start sm:top-8 sm:ml-8`}
            >
              {watchDetail?.images?.map((item, index) => {
                return (
                  <SmallImage
                    onClick={() => handleBackground(item?.src)}
                    src={item?.src}
                    name={item?.image_type}
                    width_px={"72"}
                    height_px={"72"}
                    is_border={backgroundImage === item.src}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {/* Watch Info Section */}
        <div
          ref={rightSectionRef}
          onMouseOver={() => setOver(true)}
          onMouseOut={() => setOver(false)}
          className="flex flex-col sm:basis-1/2 no-scrollbar h-full 2xl:h-[calc(100vh-5rem)] pt-[15px] xl:pt-52 relative overflow-auto "
        >
          <div
            className={`flex flex-col justify-start items-start text-white gap-[5px] xl:gap-2 px-4 sm:px-8 `}
          >
            <span className="text text-base xl:text-4xl text-white mb-1">
              {watchDetail?.product_brand_id?.name}
            </span>
            <span className="header sm:leading-[77px] title text-2xl sm:text-[64px] sm:text text-white">
              {watchDetail?.name}
            </span>
            <span className="text text-[#52F275] text-sm">
              in Stock in Kuwait
            </span>

            {/* <div className='flex flex-row justify-start items-center gap-4 w-full overflow-x-scroll pt-4'>
                            {watchDetail?.features.map((item, index) => {
                        <div className='flex flex-row justify-start items-center gap-4 w-full overflow-x-scroll no-scrollbar pt-4'>
                            {watchDetail?.features.map((item, index) => {
                                return <WatchFeature icon={item.icon} icon_name={item.icon_name} feature={item.feature} feature_value={item.feature_value} />
                            })}
                        </div>})}
                        </div> */}
            {/* <div className='flex flex-row justify-start items-center gap-4 w-full overflow-x-scroll pt-4'> */}

            <div className="flex flex-row justify-start items-center gap-4 w-full overflow-x-scroll no-scrollbar pt-4">
              <WatchFeature
                icon={size}
                icon_name={"Size"}
                feature={"Size"}
                feature_value={watchDetail?.size}
              />
              <WatchFeature
                icon={crown}
                icon_name={"Bracelet"}
                feature={"Bracelet"}
                feature_value={watchDetail?.bracelet_type_id?.name}
              />
              <WatchFeature
                icon={size}
                icon_name={"Movement"}
                feature={"Movement"}
                feature_value={watchDetail?.movement_id?.name}
              />
              <WatchFeature
                icon={timer}
                icon_name={"Year"}
                feature={"Year"}
                feature_value={watchDetail?.model_year_id?.name}
              />
              <WatchFeature
                icon={order}
                icon_name={"Condition"}
                feature={"Condition"}
                feature_value={watchDetail?.condition}
              />
              {/* })} */}
            </div>
            {/* </div> */}
          </div>

          <div className="flex flex-col  sm:flex-row items-start sm:items-center justify-start mx-4 sm:mx-8 mt-6 gap-2">
            <div className="w-fit">
              <span className="title text-lg text-white text-center  ">
                Sold By
              </span>
            </div>
            <div className="flex-1 w-full ">
              <SellerCard
                name={watchDetail?.seller?.name}
                seller_type={watchDetail?.seller?.seller_type}
                seller_avtar={watchDetail?.seller?.avatar_url}
                rating={watchDetail?.seller?.rating_stats.avg}
                rating_from={watchDetail?.seller?.rating_stats.total}
                onPress={() =>
                  navigation("/seller", {
                    state: { seller: watchDetail?.seller },
                  })
                }
              />
            </div>
          </div>
          <div className="hidden sm:flex flex-row mx-4 sm:mx-8 my-[52px]  items-center gap-4">
            <Button
              text={"Negotiate Price"}
              isBorder={true}
              onClick={handleNegotiate}
              width={"w-[188px] md:w-[319px]"}
              height={"h-12 md:h-[48px]"}
            />
            <Button
              text={"Add to Cart"}
              isBorder={false}
              onClick={() => {
                if (userDetail?.status || userDetail === undefined) {
                  navigation("/signin");
                } else {
                  navigation("/checkout-cart");
                  dispatch(
                    updateCart({ product_id: watchDetail?.id, add_qty: 1 })
                  );
                }
              }}
              iconLeft={cart_bag}
              width={"w-[188px] md:w-full"}
              height={"h-12 md:h-[56px]"}
            />
          </div>
          <div>
            <div className="hidden sm:flex flex-col justify-start mx-4 sm:mx-8 mt-6 ">
              <span className="title text-lg text-white">Watch Details</span>
              <div className="flex flex-row justify-start ">
                <div className="flex flex-col justify-start w-[343px] gap-4 items-start">
                  <WatchFeature
                    icon={size}
                    icon_name={"Size"}
                    feature={"Size"}
                    feature_value={watchDetail?.size}
                  />
                  <WatchFeature
                    icon={crown}
                    icon_name={"Bracelet"}
                    feature={"Bracelet"}
                    feature_value={watchDetail?.bracelet_type_id?.name}
                  />
                  <WatchFeature
                    icon={size}
                    icon_name={"Movement"}
                    feature={"Movement"}
                    feature_value={watchDetail?.movement_id?.name}
                  />
                </div>
                <div className="flex flex-col gap-4">
                  {" "}
                  <WatchFeature
                    icon={timer}
                    icon_name={"Year"}
                    feature={"Year"}
                    feature_value={watchDetail?.model_year_id?.name}
                  />
                  <WatchFeature
                    icon={order}
                    icon_name={"Condition"}
                    feature={"Condition"}
                    feature_value={watchDetail?.condition}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Buyer Protection */}
          <div className="flex flex-col justify-start mx-4 sm:mx-8 mt-6 ">
            <span className="title text-lg text-white">Buyer Protection</span>
            <div className="flex flex-col justify-center items-center gap-8 mt-6">
              <div className="flex flex-row gap-3 justify-start items-start w-full  ">
                <div
                  className="flex flex-row relative justify-center items-center flex-shrink-0"
                  style={{ width: "64px" }}
                >
                  <img src={shield} alt="shield" className="h-16 w-16" />
                  <img
                    src={gmt_gold_logo}
                    alt="gmt_gold_logo"
                    className="h-3 w-8 absolute"
                  />
                </div>
                <div className="flex flex-col justify-center items-start">
                  <span className="title text-lg text-white">
                    GMT24 Authenticity
                  </span>
                  <span className="text text-sm text-whiteSecondary opacity-40">
                    Watches fully checked and inspected by GMT 24 once received
                    from merchant and before delivery to buyer.
                  </span>
                </div>
              </div>

              <div className="flex flex-row gap-3 justify-start items-start w-full">
                <div
                  className="flex flex-row relative justify-center items-center flex-shrink-0"
                  style={{ width: "64px" }}
                >
                  <img src={shield_green} className="h-16 w-16" />
                  <img src={money} className="h-7 w-7 absolute" />
                </div>
                <div className="flex flex-col justify-center items-start">
                  <span className="title text-lg text-white">Money Back</span>
                  <span className="text text-sm text-whiteSecondary opacity-40">
                    Buyer can return the watch 3 days within the date of
                    delivery, with the same condition as received, providing a
                    secure shopping experience at GMT 24.
                  </span>
                </div>
              </div>

              <div className="flex flex-row gap-3 justify-start items-start w-full">
                <div
                  className="flex flex-row relative justify-center items-center flex-shrink-0"
                  style={{ width: "64px" }}
                >
                  <img src={shield_red} className="h-16 w-16" />
                  <img src={box} className="h-8 w-8 absolute" />
                </div>
                <div className="flex flex-col justify-center items-start">
                  <span className="title text-lg text-white">Fulfillment</span>
                  <span className="text text-sm text-whiteSecondary opacity-40">
                    Inspection, packaging, and delivery of the valuable
                    timepieces through GMT24’s safe and secure delivery and
                    fulfillment services.
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* About The Watch */}
          {/* <div className='flex flex-col justify-center items-start mt-6'>
                        <span className='title text-lg text-white mx-4 mb-2'>About The Watch</span>
                        <span className='text text-sm text-whiteSecondary opacity-40 mx-4 mb-6'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </span>
                        <div className='flex flex-col justify-center items-center relative w-full'>
                            <video className='w-full h-[459px]' controls>
                                <source src="https://st4.depositphotos.com/9625262/21294/v/600/depositphotos_212942970-stock-video-23rd-happy-anniversary-text-greeting.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div> */}

          {/* Rating Section */}
          <div className="pb-44">
            <div className="mt-4 ">
              <span className="title text-lg text-white ml-4">Rating</span>

              <div className={`flex flex-col gap-4 justify-center px-4 mt-5`}>
                <div className="flex flex-row gap-4 justify-center items-center">
                  <div className="w-3/12 ">
                    <span className="text text-whiteSecondary opacity-40 self-start">
                      Style
                    </span>
                  </div>
                  <div className="w-1/12 ">
                    <span className="title text-gold self-center">4 / 5</span>
                  </div>
                  <div className="w-8/12 ">
                    <Progress
                      value={watchDetail?.seller?.rating_stats?.percent["1"]}
                      color="yellow"
                      className="text-gold bg-background900"
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-4 justify-center items-center">
                  <div className="w-3/12 ">
                    <span className="text text-whiteSecondary opacity-40 self-start">
                      Value
                    </span>
                  </div>
                  <div className="w-1/12 ">
                    <span className="title text-gold self-center">4 / 5</span>
                  </div>
                  <div className="w-8/12 ">
                    <Progress
                      value={watchDetail?.seller?.rating_stats?.percent["2"]}
                      color="yellow"
                      className="text-gold bg-background900"
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-4 justify-center items-center">
                  <div className="w-3/12 ">
                    <span className="text text-whiteSecondary opacity-40 self-start">
                      Usage
                    </span>
                  </div>
                  <div className="w-1/12 ">
                    <span className="title text-gold self-center">4 / 5</span>
                  </div>
                  <div className="w-8/12 ">
                    <Progress
                      value={watchDetail?.seller?.rating_stats?.percent["3"]}
                      color="yellow"
                      className="text-gold bg-background900"
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-4 justify-center items-center">
                  <div className="w-3/12 ">
                    <span className="text text-whiteSecondary opacity-40 self-start">
                      Longevity
                    </span>
                  </div>
                  <div className="w-1/12 ">
                    <span className="title text-gold self-center">4 / 5</span>
                  </div>
                  <div className="w-8/12 ">
                    <Progress
                      value={watchDetail?.seller?.rating_stats?.percent["4"]}
                      color="yellow"
                      className="text-gold bg-background900"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Seller Reviews */}
            {watchDetail?.rating && (
              <div className="mt-9 ">
                <span className="title text-lg text-white ml-4">
                  Seller Reviews
                </span>
                {watchDetail?.rating?.reviews.slice(0, 3).map((item, index) => {
                  return (
                    <SellerReviewCard
                      customer_image={item.customer_image}
                      name={item.name}
                      rating={item.rating}
                      review={item.review}
                    />
                  );
                })}
              </div>
            )}
          </div>
          {isAtBottom && over && (
            <div
              className={`animate__animated animate__fadeInUp flex flex-col justify-center gap-[22px] w-full h-40 rounded-t-[25px] pt-[30px] px-4 pb-4 fixed bottom-0 sm:w-1/2 
            `}
              style={{
                background:
                  "linear-gradient(136deg, #383432 5.15%, #2B2827 100%)",
              }}
            >
              <div className="flex flex-row justify-between ">
                <span className="text text-whiteSecondary text-xs opacity-40 overflow-auto max-h-16">
                  {watchDetail?.description}
                </span>
                <div className="flex flex-row justify-center  text-center gap-2 items-center ">
                  <span className="text text-whiteSecondary text-end  leading-none text-sm opacity-40">
                    {watchDetail?.product_currency?.code}
                  </span>
                  <span className="text text-white text-2xl text-center items-center">
                    {watchDetail?.price}
                  </span>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center gap-4">
                <Button
                  text={"Negotiate Price"}
                  isBorder={true}
                  // onClick={() => handleNegotiate}
                  onClick={handleNegotiate}
                  width={"w-[188px] md:w-[188px]"}
                  height={"h-12 md:h-[56px]"}
                />
                <Button
                  text={"Add to Cart"}
                  isBorder={false}
                  onClick={() => {
                    if (userDetail?.status || userDetail === undefined) {
                      navigation("/signin");
                    } else {
                      navigation("/checkout-cart");
                      dispatch(
                        updateCart({ product_id: watchDetail?.id, add_qty: 1 })
                      );
                    }
                  }}
                  width={"w-[188px] md:w-[188px]"}
                  height={"h-12 md:h-[56px]"}
                />
              </div>
            </div>
          )}
          <div
            className={` flex sm:hidden flex-col justify-center gap-[22px] w-full h-40 rounded-t-[25px] pt-[30px] px-4 pb-4 fixed bottom-0 sm:w-1/2 backdrop-blur-[30px] shadow-[0_-1px_0.5px_rgba(0,0,0,0.5)]`}
          >
            <div className="flex flex-row justify-between ">
              <span className="text text-whiteSecondary text-xs opacity-40 overflow-auto max-h-16">
                {watchDetail?.description}
              </span>
              <div className="flex flex-row justify-center  text-center gap-2 items-center ">
                <span className="text text-whiteSecondary text-end  leading-none text-sm opacity-40">
                  {watchDetail?.product_currency?.code}
                </span>
                <span className="text text-white text-2xl text-center items-center">
                  {watchDetail?.price}
                </span>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center gap-4">
              <Button
                text={"Negotiate Price"}
                isBorder={true}
                // onClick={() => handleNegotiate}
                onClick={handleNegotiate}
                width={"w-[188px] md:w-[188px]"}
                height={"h-12 md:h-[56px]"}
              />
              <Button
                text={"Add to Cart"}
                isBorder={false}
                onClick={() => {
                  if (userDetail?.status || userDetail === undefined) {
                    navigation("/signin");
                  } else {
                    navigation("/checkout-cart");
                    dispatch(
                      updateCart({ product_id: watchDetail?.id, add_qty: 1 })
                    );
                  }
                }}
                width={"w-[188px] md:w-[188px]"}
                height={"h-12 md:h-[56px]"}
              />
            </div>
          </div>
          <div className={`flex flex-col sm:flex-1`}></div>
          <div className={`flex flex-col sm:flex-1`}></div>

          {/* <DownloadApp open={open} setOpen={setOpen} /> */}
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
